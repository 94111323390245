import utilsApi from '../assets/js/utils'

import ajax from '../assets/js/axios.config'

export default utilsApi.creatApi({
   getPageList:['post', '/api/tenant/list'], 
   add:['post', '/api/tenant/add'], 
   edit:['post', '/api/tenant/update'], 
   del:['post', '/api/tenant/delete'], 

   
   
   queryPlatformList:['post', '/api/payment-platform/list'], 

   chaxun:['post', '/api/tenant/get'], 


   enable:['post', '/api/tenant/enable'],
   disable:['post', '/api/tenant/disable'],


   payPlatformList:['post', '/api/payment-platform/list'],

   delete:['post', '/api/tenant/delete'],

   
   ptAdd:['post', '/api/tenant-merchant/add'],
   ptUpdate:['post', '/api/tenant-merchant/update'],

   
   platformData:['post', '/api/tenant-merchant/list'], 
   platformDel:['post', '/api/tenant-merchant/delete'], 
   platformGet:['post', '/api/tenant-merchant/get'], 

   
   inOut:['post', '/api/user/updateTenant'], 
   
   
})