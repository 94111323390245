<template>
    <div class="addAccount">
        <div class="headBox">
            <el-button type="primary" icon="el-icon-caret-left" plain size="mini" @click="back">返回</el-button>
            <div class="title">{{ dialogTitle }}</div>
        </div>
        <div class="addCard">
            <el-card class="box-card userInfo">
                <div slot="header" class="clearfix">
                    <span class="userInfo_title">账号信息</span>
                    <i
                        class="el-icon-paperclip"
                        style="float: right; padding: 3px 0; font-size: 18px; font-weight: 700"
                    ></i>
                </div>
                <el-form
                    :model="userForm"
                    ref="userForm"
                    :disabled="$route.query.isCheck == 'true'"
                    label-width="140px"
                    class="userForm"
                >
                    <el-form-item label="商户ID" prop="tenantId">
                        <el-select v-model="userForm.tenantId" placeholder="选择商户ID" size="small">
                            <el-option
                                v-for="item in tenantIdOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="账户手机号" prop="phoneNumber">
                        <el-input
                            size="small"
                            v-model="userForm.phoneNumber"
                            placeholder="输入手机号"
                            style="width: 280px"
                        ></el-input>
                    </el-form-item>
                   

                    <div class="btn">
                        <el-button type="primary" plain size="small" @click="submit()">保 存</el-button>
                    </div>
                </el-form>
            </el-card>
        </div>
    </div>
</template>
<script>
import userApi from "../../api/userApi";
import businessConfigureApi from "../../api/businessConfigureApi";
import accountConfigure from "../../api/accountConfigureApi";
import { Submenu } from "element-ui";
export default {
    name: "addAccount",
    data() {
        return {
            dialogTitle: "创建账户",

            tenantIdOptions: [],

            userForm: {
                tenantId: "",
                phoneList: [
                    {
                        phone: ""
                    }
                ],
                testPhoneList: [
                    {
                        phone: ""
                    }
                ],
                // isTestAccount: false
            }
        };
    },
    mounted() {
        if (this.$route.query.tenantId) {
            this.dialogTitle = "编辑账户";
        } else {
            this.dialogTitle = "创建账户";
        }

        if (this.$route.query.isCheck) {
            this.dialogTitle = "查看账户";
        }
        this.getShList();
        this.getData();
        this.userForm.tenantId = this.$route.query.tenantId ? this.$route.query.tenantId : "";
    },
    methods: {
        getShList() {
            businessConfigureApi.getPageList().then(res => {
                if (res.success) {
                    this.tenantIdOptions = res.data;
                }
            });
        },

        getData() {
            accountConfigure
                .getList({
                    tenantId: this.$route.query.tenantId
                })
                .then(res => {
                    console.log(res);
                    this.userForm = res.data.records[0];
                });
        },

        addPhone() {
            this.userForm.phoneList.push({
                phoneNumber: ""
            });
        },
        addtestPhone() {
            this.userForm.testPhoneList.push({
                phoneNumber: ""
            });
        },
        delPhone(item, index) {
            this.userForm.phoneList.splice(index, 1);
        },
        delTestPhone(item, index) {
            this.userForm.testPhoneList.splice(index, 1);
        },
        back() {
            this.$router.back();
        },
        submit() {
            console.log(this.userForm);
            accountConfigure.add({
                username: "",
                phoneNumber: this.userForm.phoneList[0].phoneNumber,
                tenantId: this.userForm.tenantId,
          
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.addAccount {
    background: #fff;
    min-height: 85vh;
    padding: 50px;
    margin: 20px 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
        // padding-right: 90px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        position: relative;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
        }

        .el-button {
            position: absolute;
            left: 35%;
        }
    }

    .userInfo {
        margin: 15px;
        .userInfo_title {
            font-size: 16px;
            color: #222222;
            margin-bottom: 40px;
            font-weight: 700;
        }
    }

    .btn {
        margin-top: 60px;
        text-align: center;
    }

    .addCard {
        display: flex;
        justify-content: center;
        padding-bottom: 80px;
    }

    .phoneInput {
        display: flex;
        align-items: center;
    }
    .el-icon-delete {
        padding: 3px 0;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        color: #f56c6c;
        margin-left: 8px;
    }
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both;
}

.box-card {
    width: 600px;
    /deep/.el-card__header {
        background: #f4f4f5;
    }
}
.userInfo {
    width: 510px;
}
</style>
