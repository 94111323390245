import utilsApi from '../assets/js/utils'

import ajax from '../assets/js/axios.config'

export default utilsApi.creatApi({
    add:['post', '/api/user/add'], 

    delete:['post', '/api/user/delete'], 


    getList:['post', '/api/user/page'], 

    update:['post', '/api/user/update'],
   
    

})